@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
* {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100, "YTLC" 500;
}
.container {
  /* width: 1250px; */
  max-width: 100%;
  /* margin: 0 auto; */
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* text-align: center; */
}
.highlight {
  font-weight: 300;
  font-size: 20px;
  margin: 0px 10px;
  color: #0039a6;
}
.highlight:hover {
  transition: all ease 0.3s;
  font-weight: 300;
  font-size: 20px;
  margin: 0px 10px;
  color: rgb(74, 0, 101);
  text-shadow: 0 0 10px #0039a6;
}
.backimg {
  background-size: cover;
}
.warnings {
  color: darkblue;
}
.main-container {
  height: 600px;
  padding: 30px 100px;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
}
.second-container {
  border-radius: 20px;
  width: 50%;
  height: 100%;
  margin: 50px 15px;
}
.info-container {
  height: 100%;
  width: 50%;
  background-color: #fff;
  margin: 50px 15px;
  border-radius: 20px;
  /* box-shadow: 0px 0px 5px black; */
  text-align: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.info-container h1 {
  font-size: 55px;
  margin-bottom: 10px;
  border-bottom: 2px solid black;
  display: inline-block;
}

.info-container h3 {
  font-size: 50px;
  margin-bottom: 50px;
  font-weight: 700;
  padding: 30px 0px;
  animation: appear 1s linear;
}
.info-container h3 span {
  font-size: 50px;
  margin-bottom: 50px;
  font-weight: 700;
  padding: 30px 0px;
  color: #0039a6;
}
.info-container p {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  text-align: left;
  padding: 0px 50px;
  word-spacing: 5px;
  animation: appear 1s linear;
  transition: all ease 1s;
  word-wrap: break-word;
  
}

.image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;

  animation: appear2 0.5s linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}
.profile {
  height: 50%;
  width: 100%;

  /* margin: 5px; */
  overflow: hidden;

  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.experience-container {
  height: 48%;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.row1 {
  width: 100%;
  height: 48%;
  display: flex;
}
.row2 {
  width: 100%;
  height: 48%;
  display: flex;
}
.first {
  height: 90%;
  width: 50%;
  background-color: #ffffffb4;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  padding: 5px;

  animation: appear2 1s linear;
}

.secound {
  height: 90%;
  width: 50%;
  background-color: #ffffffb4;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  padding: 5px;

  animation: appear2 1s linear;
}
.third {
  height: 90%;
  width: 50%;
  background-color: #ffffffb4;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  padding: 5px;

  animation: appear2 1s linear;
}
.fourth {
  height: 90%;
  width: 50%;
  background-color: #ffffffb4;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  padding: 5px;

  animation: appear2 1s linear;
}
.first:hover,
.secound:hover,
.third:hover,
.fourth:hover {
  transition: all ease 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.649);
}
.experience-container h1 {
  padding: 0px 15px;
  font-size: 45px;
  text-align: left;
}
.experience-container h3 {
  font-size: 25px;
}

.btn {
  width: 150px;
  max-width: 200px;
  border-radius: 25px;
  text-align: center;
  padding: 8px 20px;
  font-size: 20px;
  margin: 30px 10px;
  font-weight: 500;
  border: 1px solid rgb(10, 10, 10);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  animation: appear2 1s linear;
}
.btn:hover {
  transition: ease all 0.5s;
  background-color: black;
  color: #fff;
  box-shadow: 0 8px 32px 0 rgba(35, 42, 139, 0.5);
}

/* sevices */

.service-container {
  height: 100%;
  min-height: 500px;
  padding: 30px 80px;
  margin: 50px 15px;
  text-align: center;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.h3-heading {
  font-size: 5vh;
  margin-bottom: 25px;
}


.service-item {
  padding: 10px;
  height: 80vh;
  max-width: 600px;
  max-width: 29%;
  align-items: center;
  display: inline-block;
  border-radius: 20px;
  flex: 0 0 50%;
  position: relative;
  margin: 10px 10px;
  background-color: #fff;

  overflow: hidden;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);

  animation: appear2 1s linear;
}

.service-item:hover {
  transition: ease all 0.5s;
  max-width: 30%;
  box-shadow: 0 8px 32px 0 rgb(32, 35, 69);
  transform: scale(1.05);
}

.service-item p {
  color: var(--bg-black-700);
  text-align: center;
  padding-bottom: 5px;
  font-size: 15px;
}
.service-item h1 {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 10px;
  color: var(--text-black-50);
}
.servicecardpic {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* complain */

.complain-container {
  display: grid;
  margin: 10px 20px;
  align-items: center;
  text-align: center;

  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.complain-container h1 {
  margin: 20px;
  font-weight: 800;
}

.inputfield {
  margin: 10px 0px;
  text-align: start;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 50px;
}

.inputfield input {
  border-radius: 3px;
  border: 1.5px solid black;
  padding: 8px;
  border-radius: 10px;
  width: 50%;
  max-width: 300px;
  background: transparent;
  font-size: 20px;
}
.inputfield select {
  border-radius: 3px;
  border: 1.5px solid black;
  padding: 8px;
  border-radius: 10px;
  width: 50%;
  background: transparent;
  font-size: 20px;
  max-width: 300px;
}
.inputfield label {
  padding: 5px 10px;
  font-weight: 500;
  font-size: 25px;

  width: 30%;
}

/*choose us*/

.chooseus-containe {
  height: 100%;
  /* width: 100%; */
  margin: 50px 15px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.chooseus-containe h1 {
  margin: 10px 10px;
  animation: appear3 0.3s linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}
.chooseus-containe li {
  animation: appear3 0.3s linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}
.chooseus-containe span {
  color: #0039a6;
  font-family: Arial, Helvetica, sans-serif;
}
.chooseus-containe p {
  font-family: Arial, Helvetica, sans-serif;
  width: auto;
}
.cursor {
  transition: all ease 0.1s;
  height: 20px;
  width: 20px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  border-radius: 50%;
  z-index: 9999;
  pointer-events: none;
  mix-blend-mode: difference;
}
.chooseus-containe:hover ~ .cursor {
  transform: scale(10);
  display: none;
}

.slider {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}

.slider img {
  width: 100%;
  border-radius: 10px;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}
.infocards {
  max-width: 100%;
  text-align: center;
  align-items: center;
    margin: 50px 15px;

}
.icard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* width: 98%; */
  margin: 10px 0px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.icard:hover {
  transition: all ease 0.5s;
  scale: 1.01;
  box-shadow: 0 8px 32px 0 rgba(26, 32, 118, 0.656);
}
.cardimg {
  width: 30%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.cardimg img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-position: center;
}
.cardtext {
  width: 70%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cardtext p {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  text-align: left;
  padding: 0px 50px;
  word-spacing: 5px;
  transition: all ease 1s;
}
.heading {
  font-size: 40px;
  width: fit-content;
  margin-bottom: 40px;
  font-weight: 600;
  font-family: cursive;
  border-bottom: 3px solid black;
  color: #0039a6;
}

/* Contact form */

.conetctform {
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.conetctform section {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  gap: 5px;
}
.conetctform input {
  width: 400px;
  border-radius: 10px;
  background: transparent;
  border: 1.5px solid black;
  padding: 8px;
  max-width: 800px;
  margin: 5px;
}
.conetctform textarea {
  width: 400px;
  border-radius: 10px;
  background: transparent;
  border: 1.5px solid black;
  padding: 8px;
  max-width: 800px;
}

/* animation */

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes appear2 {
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}
@keyframes appear3 {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media (max-width: 1450px) {
  .experience-container h1 {
    text-align: center;
    font-size: 40px;
  }
  .experience-container h3 {
    text-align: center;
    font-size: 30px;
  }
  .service-container {
    height: 100%;
    padding: 10px;
      margin: 30px 10px;
  }
  .inputfield {
    padding: 10px;
    margin: 5px;
  }
  .service-item {
    flex: 0 0 100%;
  }
  .service-item:hover {
    transition: ease all 0.5s;
    max-width: 29%;
    box-shadow: 0 8px 32px 0 rgb(32, 35, 69);
    transform: scale(1.03);
  }
  .main-container {
    height: 850px;
          /* margin: 30px 10px; */

  }
  .profile {
    height: 50%;
    width: 100%;
    display: block;
  }
  .chooseus-containe {
    /* width: 97%; */
      margin: 30px 10px;

  }
}
@media (max-width: 991px) {
  .cursor {
    display: none;
  }
  .service-container {
    height: 100%;
    padding: 10px;
      margin: 30px 10px;
  }
  .service-item:hover {
    transition: ease all 0.5s;
    max-width: 46%;
    box-shadow: 0 8px 32px 0 rgb(32, 35, 69);
    transform: scale(1.05);
  }
  .inputfield {
    height: 100%;
    padding: 10px;
    margin: 5px;
  }
  .service-item {
    flex: 0 0 100%;
    max-width: 100%;
    height: 100%;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
  }
  .service-item:hover {
    transition: ease all 0.5s;
    max-width: 100%;
    box-shadow: 0 8px 32px 0 rgb(32, 35, 69);
    transform: scale(1.05);
  }
  .info-container h3 {
    padding: 0 0;
    font-size: 40px;
  }
  .info-container p {
    margin: 20px;
    padding: 0 0;
  }
  .profile {
    display: none;
  }
  .main-container {
    display: block;
    padding: 0;
    height: 100%;
          /* margin: 30px 10px; */

  }
  .info-container {
    width: auto;
      margin: 30px 10px;
    padding: 0;
    overflow: hidden;
  }
  .row1,
  .row2 {
    display: block;
    padding: 0px;
  }
  .experience-container h1 {
    text-align: center;
  }
  .first,
  .secound,
  .third,
  .fourth {
    width: -webkit-fill-available;
    /* min-width: 95%; */
    margin: 0;
    padding: 10px 10px;
    margin: 10px 10px;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
  }

  .experience-container,
  .second-container {
    flex: 0 0 100%;
    max-width: 100%;
    width: auto;
    margin: 30px 10px;

  }
  .chooseus-containe {
    /* max-width: 92%; */
          margin: 30px 10px;

  }
  .conetctform input {
    width: 300px;
    border-radius: 10px;
    background: transparent;
    border: 1.5px solid black;
    padding: 8px;
    max-width: 800px;
    margin: 5px;
  }
  .conetctform textarea {
    width: 300px;
    border-radius: 10px;
    background: transparent;
    border: 1.5px solid black;
    padding: 8px;
    max-width: 800px;
    margin: 5px;
  }
}

@media (max-width: 420px) {
  .cursor {
    display: none;
  }
  .service-item {
    flex: 0 0 100%;
    max-width: 100%;
    height: 39vh;
  }
  .service-item {
    height: 100%;
  }
  .service-item:hover {
    transition: ease all 0.5s;
    max-width: 100%;
    box-shadow: 0 8px 32px 0 rgb(32, 35, 69);
    transform: scale(1.05);
  }
  .icard {
    flex-direction: column;
    width: 95%;
    padding: 5px;
  }
  .cardimg {
    width: 97%;

    background-position: center;
  }
  .cardtext {
    width: 100%;
  }
  .cardtext p {
    padding: 5px;
  }
  .heading {
    display: none;
  }

  .inputfield {
    display: block;
  }
  .inputfield label {
    width: 100%;
    margin: 10px;
  }
  .inputfield input {
    width: 80%;
    margin: 10px;
  }
  .inputfield select {
    width: 90%;
    margin: 10px;
  }
  .conetctform input {
    width: 200px;
    border-radius: 10px;
    background: transparent;
    border: 1.5px solid black;
    padding: 8px;
    max-width: 800px;
    margin: 5px;
  }
  .conetctform textarea {
    width: 200px;
    border-radius: 10px;
    background: transparent;
    border: 1.5px solid black;
    padding: 8px;
    max-width: 800px;
    margin: 5px;
  }
  .highlight{
        font-weight: 300;
    font-size: 12px;
    ;
  }
}
